import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper, bnHelper } from "@/helpers/bignumber-helper";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from "mobx";
import { asyncAction, task } from "mobx-utils";
import moment from "moment";
import { Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { priceHelper } from "../../../helpers/pancakePriceHelper";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { TicketHandler } from "@/helpers/ticket-handler";

export class TicketViewModel {
  _disposers: IReactionDisposer[] = [];
  private _unsubcrible = new Subject();

  @observable isDialogLoading = false;
  @observable isFirstLoad = false;
  @observable tokenPrice = FixedNumber.from("0");

  @observable premiumTicketRate = "";
  @observable standardTicketRate = "";
  @observable nftPerTicket = "1";

  @observable stakeDialog = false;
  @observable increaseStakeDialog = false;
  @observable unstakeDialog = false;
  @observable tokenStakeAmount = Zero;

  @observable premiumPrice = Zero;
  @observable standardPrice = Zero;
  @observable premiumInputAmount = "";
  @observable standardInputAmount = "";

  @observable approved = false;
  @observable approving = false;

  @observable ticketAmountText = "";
  @observable totalStakeSB = Zero;
  @observable userStandardTicketNo = Zero;
  @observable userPremiumTicketNo = Zero;
  @observable increaseTicketType = "";
  @observable increaseTicketNo = "";

  @observable lockDuration = moment.duration(0, "second"); // in seconds
  @observable lockedUntil = moment().add(this.lockDuration);
  @observable userLockedUntil: moment.Moment | null = null;
  @observable stakeIncreaseLockedUntil = moment().add(this.lockDuration);

  @observable userStakedAmount = FixedNumber.from("0");
  @observable lastStakeTime: moment.Moment | null = null;
  @observable totalLockedAmount = FixedNumber.from("0");
  @observable totalValueLocked = FixedNumber.from("0");
  @observable userLength = "";
  @observable dataLoading = false;

  ticketHandler?: TicketHandler;

  constructor() {
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.ticketHandler?.injectMetamask(walletStore.web3!);
        }
      ),
    ];
  }

  destroy() {
    this._unsubcrible.next();
    this._unsubcrible.complete();
    this._disposers.forEach((d) => d());
  }

  async loadData() {
    const ticketHandler = new TicketHandler();
    this.ticketHandler = ticketHandler;
    if (walletStore.isMetamask) this.dataLoading = true;

    try {
      await Promise.all([ticketHandler.load(), this.getTokenPrice()]);
      this.lockDuration = this.ticketHandler.lockDuration!;
      this.premiumPrice = this.ticketHandler.premiumPrice!;
      this.standardPrice = this.ticketHandler.standardPrice!;
      this.premiumTicketRate = this.ticketHandler.premiumTicketRate;
      this.standardTicketRate = this.ticketHandler.standardTicketRate;
      this.nftPerTicket = this.ticketHandler.nftPerTicket;
    } catch (error) {
      console.error("loadData", error);
    }

    timer(0, 20000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        this.getUserLength();
        const lockedAmount = await ticketHandler.getTotalLockedAmount();
        runInAction(() => {
          this.totalLockedAmount = lockedAmount;
          this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
        });
      });

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId == process.env.VUE_APP_CHAIN_ID) {
            ticketHandler.injectMetamask(walletStore.web3!);
            ticketHandler
              .approved(walletStore.account)
              .then((approved) => runInAction(() => (this.approved = approved)));

            timer(0, 10000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo();
              });
          }
        }
      )
    );
    if (!walletStore.connected || !walletStore.isChainIdValid) this.dataLoading = false;
  }

  @asyncAction *fetchPoolInfo() {
    if (!walletStore.account || !this.ticketHandler) {
      this.userStakedAmount = FixedNumber.from("0");
    } else {
      this.getUserLastStaleTime();
      const [{ amount, standardTicketNo, premiumTicketNo, userLockedUntil }, lockedAmount] = yield Promise.all([
        this.ticketHandler?.getUserInfo(walletStore.account),
        this.ticketHandler.getTotalLockedAmount(),
      ]);

      this.userStakedAmount = amount;
      this.userPremiumTicketNo = premiumTicketNo;
      this.userStandardTicketNo = standardTicketNo;
      this.totalLockedAmount = lockedAmount;
      this.userLockedUntil = userLockedUntil;
      priceHelper.getTokenPrice().then((p) => {
        runInAction(() => (this.tokenPrice = priceHelper.tokenPriceBUSD));
      });
      this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));

      if (!this.isFirstLoad) this.isFirstLoad = true;
    }
    this.dataLoading = false;
  }

  @asyncAction *getUserLastStaleTime() {
    const { lastStakeTime } = yield this.ticketHandler?.getUserInfo(walletStore.account);
    this.lastStakeTime = lastStakeTime;
  }

  @asyncAction *approve() {
    this.approving = true;
    try {
      yield this.ticketHandler!.approve(walletStore.account);
      this.approved = true;
    } catch (error) {
      this.approved = false;
      snackController.error(error.message);
    }
    this.approving = false;
  }

  @action.bound changePremiumInputAmount(amount) {
    this.premiumInputAmount = amount;
  }
  @action.bound changeStandardInputAmount(amount) {
    this.standardInputAmount = amount;
  }

  @action.bound requestStake(isPremium) {
    this.lockedUntil = moment().add(this.lockDuration);
    this.stakeDialog = true;
    if (isPremium) {
      this.ticketAmountText = `${this.premiumInputAmount} Premium ${
        +this.premiumInputAmount > 1 ? "tickets" : "ticket"
      }`;
      this.totalStakeSB = this.premiumSbAmount;
    } else {
      this.ticketAmountText = `${this.standardInputAmount} Standard ${
        +this.standardInputAmount > 1 ? "tickets" : "ticket"
      }`;
      this.totalStakeSB = this.standardSbAmount;
    }
  }
  @action.bound increaseStakeToken() {
    this.stakeIncreaseLockedUntil = moment().add(this.lockDuration);
    this.increaseStakeDialog = true;
  }
  @action.bound requestUnstake() {
    this.unstakeDialog = true;
  }

  @action.bound increaseInputChanged(ticketNo) {
    this.increaseTicketNo = ticketNo;
  }

  @computed get increaseSbAmount() {
    if (!this.validIncreaseStakeToken) {
      return Zero;
    } else {
      if (this.increaseTicketType === "premium") {
        return this.premiumPrice.mulUnsafe(FixedNumber.from(this.increaseTicketNo));
      } else {
        return this.standardPrice.mulUnsafe(FixedNumber.from(this.increaseTicketNo));
      }
    }
  }

  @computed get increaseBalanceInsufficient() {
    return bnHelper.gt(this.increaseSbAmount, walletStore.sbBalance);
  }

  @computed get validIncreaseStakeToken() {
    try {
      FixedNumber.from(this.increaseTicketNo);
      return +this.increaseTicketNo > 0 && +this.increaseTicketNo % 1 === 0;
    } catch (error) {
      return false;
    }
  }

  @asyncAction *getTokenPrice() {
    yield priceHelper.getTokenPrice();
    this.tokenPrice = priceHelper.tokenPriceBUSD;
  }

  @action.bound ticketChanged(type) {
    this.increaseTicketType = type;
  }

  @asyncAction *confirmStake() {
    this.isDialogLoading = true;

    try {
      yield this.ticketHandler!.deposit(
        walletStore.account,
        this.totalStakeSB,
        this.standardInputAmount,
        this.premiumInputAmount
      );
      snackController.success("Stake LCEO successfully!");
      this.fetchPoolInfo();
      this.getUserLastStaleTime();
      this.getUserLength();
      this.stakeDialog = false;
    } catch (err) {
      snackController.error(err.message);
      console.error(err);
    } finally {
      this.isDialogLoading = false;
    }
  }
  @asyncAction *confirmIncrease() {
    this.isDialogLoading = true;

    let standardInputAmount = "0";
    let premiumInputAmount = "0";
    if (this.increaseTicketType === "premium") premiumInputAmount = this.increaseTicketNo;
    else standardInputAmount = this.increaseTicketNo;

    const totalStakeSB = FixedNumber.from(standardInputAmount)
      .mulUnsafe(this.standardPrice)
      .addUnsafe(FixedNumber.from(premiumInputAmount).mulUnsafe(this.premiumPrice));
    try {
      yield this.ticketHandler!.deposit(walletStore.account, totalStakeSB, standardInputAmount, premiumInputAmount);
      snackController.success("Increase stake SB successfully!");
      this.increaseTicketNo = "";
      this.fetchPoolInfo();
      this.getUserLastStaleTime();
      this.increaseStakeDialog = false;
    } catch (err) {
      snackController.error(err.message);
      console.error(err);
    } finally {
      this.isDialogLoading = false;
    }
  }

  @asyncAction *confirmUnstake() {
    this.isDialogLoading = true;
    this.lockedUntil = moment().add(this.lockDuration);

    try {
      yield this.ticketHandler!.unstake(walletStore.account);
      snackController.success("Unstake successfully");
      this.fetchPoolInfo();
      this.unstakeDialog = false;
    } catch (err) {
      snackController.error(err.message);
    } finally {
      this.isDialogLoading = false;
    }
  }

  @asyncAction *getUserLength() {
    const userLength = yield this.ticketHandler?.getUserLength();
    this.userLength = userLength;
  }

  @action.bound cancelStakeDialog() {
    this.stakeDialog = false;
  }
  @action.bound cancelIncreaseStakeDialog() {
    this.increaseStakeDialog = false;
  }
  @action.bound cancelUnstakeDialog() {
    this.unstakeDialog = false;
  }

  @computed get isStaked() {
    return !!this.lastStakeTime;
  }
  @computed get userStakedValue() {
    return this.userStakedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
  }
  @computed get isStakingToken() {
    return bnHelper.gt(this.userStakedAmount, Zero);
  }

  @computed get premiumSbAmount() {
    return this.validPremiumInputAmount ? this.premiumPrice.mulUnsafe(FixedNumber.from(this.premiumInputAmount)) : Zero;
  }
  @computed get standardSbAmount() {
    return this.validStandardInputAmount
      ? this.standardPrice.mulUnsafe(FixedNumber.from(this.standardInputAmount))
      : Zero;
  }

  @computed get lockInDays() {
    return this.lockDuration.asDays();
  }
  @computed get lockInSeconds() {
    return this.lockDuration.asSeconds();
  }
  @computed get unstakeTime() {
    return this.lastStakeTime?.clone().add(this.lockDuration);
  }
  @computed get canUnstake() {
    return moment().isAfter(this.unstakeTime) && bnHelper.gt(this.userStakedAmount, Zero);
  }

  @computed get validPremiumInputAmount() {
    try {
      FixedNumber.from(this.premiumInputAmount);
      return +this.premiumInputAmount > 0 && +this.premiumInputAmount % 1 === 0;
    } catch (error) {
      return false;
    }
  }
  @computed get validStandardInputAmount() {
    try {
      FixedNumber.from(this.standardInputAmount);
      return +this.standardInputAmount > 0 && +this.standardInputAmount % 1 === 0;
    } catch (error) {
      return false;
    }
  }

  @computed get totalStaked() {
    try {
      return this.userStakedAmount.addUnsafe(this.increaseSbAmount);
    } catch (error) {
      return this.userStakedAmount;
    }
  }
}
