

















































































































































































































































































import { Observer } from "mobx-vue";
import { Component, Provide, Vue } from "vue-property-decorator";
import { TicketViewModel } from "../viewmodels/ticket-viewmodel";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({
    components: {
        Ticket: () =>
            import ("../components/ticket.vue"),
        Stake: () =>
            import ("../components/stake.vue"),
        UnStakeDialog: () =>
            import ("@/modules/ticket/dialogs/unstake-dialog.vue"),
        IncreaseStakeDialog: () =>
            import ("@/modules/ticket/components/increase-stake-dialog.vue"),
    },
})
export default class StakeTicketPage extends Vue {
    @Provide() vm = new TicketViewModel();
    walletStore = walletStore;

    openConfirmDialog(isPremium) {
        this.vm.requestStake(isPremium);
    }
}
