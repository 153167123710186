/* eslint-disable prettier/prettier */
import { FixedNumber } from "@ethersproject/bignumber";
import { walletStore } from "@/stores/wallet-store";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { blockchainHandler } from "@/blockchain";
import moment from "moment";
import Web3 from "web3";
import { asyncAction } from "mobx-utils";
import { bnHelper } from "./bignumber-helper";

const DECIMALS = 18;
const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!;

async function sendRequest(fx, from, gasLimit = "") {
  return await new Promise((resolve, reject) => {
    fx.send({ from, gasLimit })
      .on("receipt", (result) => resolve(result))
      .on("error", (error) => reject(error));
  });
}

export class TicketHandler {
  ticketContract: any;
  tokenContract: any;
  lockDuration?: moment.Duration;
  rewardToken?: string;
  standardPrice?: FixedNumber;
  premiumPrice?: FixedNumber;
  lockedAmount?: FixedNumber;

  usedTicket?: FixedNumber;
  summonedIds?: Array<string>;

  lotteryContract?: any;
  premiumTicketRate = "";
  standardTicketRate = "";
  nftPerTicket = "1";
  // nftContract?: any;

  constructor() {
    this.tokenContract = new web3.eth.Contract(require("./erc20.abi.json"), process.env.VUE_APP_TOKEN_ADDRESS);
    this.ticketContract = new web3.eth.Contract(require("./ticket.abi.json"), process.env.VUE_APP_STAKE_TICKET_ADDRESS);
    this.lotteryContract = new web3.eth.Contract(require("./lottery.abi.json"), process.env.VUE_APP_LOTTERY_ADDRESS);
  }

  async load() {
    try {
      const stakeMethods = this.ticketContract.methods;
      const [rewardToken, lockDuration, standardPrice, premiumPrice] = await blockchainHandler.etherBatchRequest(web3, [
        stakeMethods.rewardToken(),
        stakeMethods.lockDuration(),
        stakeMethods.ticketPrices(0),
        stakeMethods.ticketPrices(1),
      ]);
      this.rewardToken = web3.utils.toChecksumAddress(rewardToken + "");
      this.standardPrice = FixedNumber.from(`${bnHelper.fromDecimals(standardPrice, DECIMALS)}`);
      this.premiumPrice = FixedNumber.from(`${bnHelper.fromDecimals(premiumPrice, DECIMALS)}`);

      const lotteryMethods = this.lotteryContract.methods;
      const [premiumTicketRate, standardTicketRate, nftPerTicket] = await blockchainHandler.etherBatchRequest(web3, [
        lotteryMethods.premiumTicketRate(),
        lotteryMethods.standardTicketRate(),
        lotteryMethods.nftPerTicket(),
      ]);
      this.premiumTicketRate = premiumTicketRate as string;
      this.standardTicketRate = standardTicketRate as string;
      this.nftPerTicket = nftPerTicket as string;

      this.usedTicket = await this.getUsedTicket();
      this.lockDuration = moment.duration(lockDuration + "", "seconds");
      this.tokenContract = new web3.eth.Contract(require("./erc20.abi.json"), this.rewardToken);
    } catch (error) {
      console.error(error);
    }
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.ticketContract = new web3.eth.Contract(
        require("./ticket.abi.json"),
        process.env.VUE_APP_STAKE_TICKET_ADDRESS
      );
      this.tokenContract = new web3.eth.Contract(require("./erc20.abi.json"), process.env.VUE_APP_TOKEN_ADDRESS);
      this.lotteryContract = new web3.eth.Contract(require("./lottery.abi.json"), process.env.VUE_APP_LOTTERY_ADDRESS);
    }
  }

  async premiumSpin(account) {
    const f = this.lotteryContract.methods.sprin(1);
    const res = await sendRequest(f, account, "200000");
    const summonedEvent = (res as any).events["Summoned"];
    this.summonedIds = summonedEvent.returnValues.nftIds as Array<string>;
    return this.summonedIds && this.summonedIds[0];
  }

  async standardSpin(account) {
    const f = this.lotteryContract.methods.sprin(0);
    const res = await sendRequest(f, account, "200000");
    const summonedEvent = (res as any).events["Summoned"];
    this.summonedIds = summonedEvent.returnValues.nftIds as Array<string>;
    return this.summonedIds && this.summonedIds[0];
  }

  async getUsedTicket() {
    const usedTicket = await this.lotteryContract.methods.usedTicket().call();
    return FixedNumber.from(usedTicket);
  }

  async approved(account) {
    try {
      const allowance = await this.tokenContract.methods
        .allowance(account, process.env.VUE_APP_STAKE_TICKET_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approve(account) {
    try {
      const f = this.tokenContract.methods.approve(
        process.env.VUE_APP_STAKE_TICKET_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  async approvedLottery(account) {
    try {
      const allowance = await this.tokenContract.methods.allowance(account, process.env.VUE_APP_LOTTERY_ADDRESS).call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approveLottery(account) {
    try {
      const f = this.tokenContract.methods.approve(
        process.env.VUE_APP_LOTTERY_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.error("error", error);
    }
  }

  async getTotalLockedAmount() {
    const amount = await this.ticketContract.methods.totalAmount().call();
    return FixedNumber.from(`${bnHelper.fromDecimals(amount, DECIMALS)}`);
  }
  async deposit(account, amount, standardTicketNo, premiumTicketNo) {
    const f = this.ticketContract.methods.deposit(bnHelper.toDecimalString(`${amount.toString()}`, DECIMALS), [
      +standardTicketNo.toString(),
      +premiumTicketNo.toString(),
    ]);
    await sendRequest(f, account);
  }
  async unstake(account) {
    const f = this.ticketContract.methods.withdraw();
    await sendRequest(f, account);
  }

  async getTicketPrices() {
    const standardPrice = await this.ticketContract.methods.ticketPrices(0).call();
    const premiumPrice = await this.ticketContract.methods.ticketPrices(1).call();

    return {
      standardPrice: bnHelper.fromDecimals(standardPrice, DECIMALS),
      premiumPrice: bnHelper.fromDecimals(premiumPrice, DECIMALS),
    };
  }

  async getUserInfo(account) {
    const { 0: amount, 1: lastStakeTime } = await this.ticketContract.methods.userInfo(account).call();

    const standardTicketNo = await this.ticketContract.methods.userTicketNo(account, 0).call();
    const premiumTicketNo = await this.ticketContract.methods.userTicketNo(account, 1).call();

    const standardHistoryTicketNo = await this.ticketContract.methods.historyUserTicketNo(account, 0).call();
    const premiumHistoryTicketNo = await this.ticketContract.methods.historyUserTicketNo(account, 1).call();
    const userLastStakeTime = +lastStakeTime ? moment(+lastStakeTime * 1000) : null;

    return {
      amount: FixedNumber.from(`${bnHelper.fromDecimals(amount, DECIMALS)}`),
      lastStakeTime: userLastStakeTime,
      userLockedUntil: userLastStakeTime ? userLastStakeTime.clone().add(this.lockDuration) : null,
      standardTicketNo: FixedNumber.from(standardTicketNo),
      premiumTicketNo: FixedNumber.from(premiumTicketNo),
      standardHistoryTicketNo: FixedNumber.from(standardHistoryTicketNo),
      premiumHistoryTicketNo: FixedNumber.from(premiumHistoryTicketNo),
    };
  }

  async getUserLength() {
    const userLength = await this.ticketContract.methods.getUserLength().call();
    return userLength;
  }
}
